ul {
  list-style-type: none;
}

.nav-list {
  display: flex;
  margin-right: 1.5em;
}

.nav-item {
  margin-left: 1.5em;
}

.nav-item a {
  font-weight: 600;
}

.nav-hamburger {
  display: none;
}

.nav-item a:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .nav-list {
    align-items: center;
    display: none;
    flex-direction: column;
    height: 100%;
    inset: 0;
    justify-content: center;
    position: fixed;
    width: 100%;
    z-index: 2;
  }

  .nav-item {
    margin: 0.5em 0;
  }

  .nav_hamburger {
    display: flex;
    margin-left: 0.8em;
    z-index: 2;
  }
}
