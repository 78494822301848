.header {
  height: 128px;
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
  justify-content: space-between;
}

.center {
  display: flex;
  align-items: center;
}

@media (max-width: 600px) {
  .header {
    height: 96px;
  }
}

a {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #0000;
  background-image: linear-gradient(90deg,#F79533 0%,#F37055 15%,#EF4E7B 30%,#A166AB 44%,#5073B8 58%,#1098AD 72%,#07B39B 86%,#6DBA82 100%);
  background-size: cover;
  text-decoration: none; 
}

a:hover {
  text-decoration: underline !important;
}

.link {
  font-size: 24px;
}

.link:focus:before, .link:hover:before {
  width: 100%;
}

.link:hover {
  color: pink;
}