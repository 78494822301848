.skills-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  width: 50%;
}

.skills-list-item {
  margin: 8px;
}

@media only screen and (max-width: 768px) {
  .skills-list {
    padding-inline-start: 0px;
  }
}
