@media only screen and (max-width: 1024px) {
  .projects-grid {
    display: inline-flex;
    flex-direction: column;
    gap: 36px;
    padding: 0 48px 32px;
  }

  .project {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 16px 0;
    width: 100%;
  }

  .project > img {
    height: 100%;
    width: 100%;
  }
}

@media only screen and (min-width: 1025px) {
  .projects-grid {
    display: inline-flex;
    gap: 24px;
    justify-content: space-around;
    padding-bottom: 48px;
  }

  .project {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin: 0 auto;
    max-width: 75%;
    padding: 2em;
    text-align: center;
    transition: transform 0.2s linear;
  }

  .project > img {
    height: 150px;
    width: 260px;
  }

  .project:hover {
    transform: translateY(-7px);
  }

  .project-description {
    margin-top: 16px;
  }

  .project-title {
    margin-top: 0;
  }
}

.link,
.project-title {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #0000;
  background-image: linear-gradient(
    90deg,
    #f79533 0%,
    #f37055 15%,
    #ef4e7b 30%,
    #a166ab 44%,
    #5073b8 58%,
    #1098ad 72%,
    #07b39b 86%,
    #6dba82 100%
  );
  background-size: cover;
  text-decoration: none;
}
