form {
  display: flex;
  flex-direction: column;
  margin: auto auto;
  max-width: 50%;
}

label {
  text-align: left;
}

input {
  background-color: #fff;
  border: 1px solid #eceff9;
  border-radius: 5px;
  font-size: 16px;
  margin: 4px 0 32px;
  padding: 12px;
}

textarea {
  background-color: #fff;
  border: 1px solid #eceff9;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  margin: 4px 0 32px;
  padding: 12px;
}

button {
  background: rgb(18, 27, 43);
  border-radius: 6px;
  border: 1px solid #eceff9;
  box-shadow: unset;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  margin: auto auto;
  padding: 10px;
  transition: 150ms;
  width: 30%;
}

.successMessage {
  padding-top: 48px;
}

@media only screen and (min-width: 1025px) {
  form {
    margin: auto auto;
    max-width: 50%;
  }
}
